<template>
    <div id="login">
        <img :src="require('../assets/bg.png')" class="bg">
        <div class="d-flex justify-content-between m-3">
            <div></div>
            <div class="form-group d-flex">
                <h6 class="my-auto ms-3" style="color:white;">{{ $t('home.chooseLang') }}:</h6>
                <select 
                    class="form-control text-center" 
                    id="exampleFormControlSelect1"
                    style="
                        width:100px;
                        background-color:#231f20;
                        color:#c08000;
                        border:none;
                        margin-left:0.5em;
                    "
                    @change="changeLanguage()"
                    v-model="chosenLang"
                >
                    <option value="en">English</option>
                    <option value="kr">한국어</option>
                </select>
            </div>
        </div>
        <div class="wrapper mx-auto">
            <div class="logo">
                <img :src="require('../assets/logo.png')" >
            </div>
            <h4 class="text-center" style="color:#c08000;">{{ state == 'login' ? $t('login.logIn') : $t('login.forgotPINLabel') }}</h4>
            <form v-on:submit.prevent="onLogin" v-if="state == 'login'">
                <div class="alert alert-danger" v-if="errors.login.msg != ''">
                    {{ errors.login.msg }}
                </div>
                
                <div class="btn-group email-or-mobile">
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">{{ $t('login.mobileNumber') }}</button>
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">{{ $t('login.emailAddress') }}</button> 
                </div>

                <div class="btn-group email-or-mobile">
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'accountNo' }" v-on:click="toggleLoginOption('accountNo')">{{ $t('login.accountNumber') }}</button> 
                </div>

                <div class="form-group" v-if="selected =='email'">
                    <label for="formGroupExampleInput">{{ $t('login.emailAddress') }}:</label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                <div class="form-group" v-if="selected =='accountNo'">
                    <label for="accountNo">{{ $t('login.accountNumber') }}:</label>
                    <input type="text" class="form-control" id="accountNo" v-model="login.loginId" @keypress="isNumber($event)">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>


                <div class="form-group mobile-container" v-if="selected =='mobile'">
                    <label for="loginId">{{ $t('login.mobileNumber') }}:</label> 
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn text-white" type="button" @click="toggleCountryList">
                            <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                            <span>+{{ mobileCode }}</span>
                            </button>
                        </div>

                        <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
                    </div>
                    <div class="country-list" v-if="showCountry">
                        <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)" :key="l.country">
                                <div>
                                    <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                                </div>

                                <div class="country-name"> <span> {{ l.country }} </span> </div>
                            </div>
                        </div>
                    </div>
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                

                <div class="form-group">
                    <label for="exampleFormControlInput1">{{ $t('login.pinLabel') }}:</label>
                    <div class="input-group mb-1">
                        <div class="input-group-append">
                            <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword">{{ hideOrShow }}</button>
                        </div>
                        <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
                    </div>
                    <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>

                    <div>
                        <a href="#" @click.prevent="toggleLogin('forgot-pass')" class="forgot">{{ $t('login.forgotPIN') }}</a>
                    </div>
                </div>

                <div id="captcha" class="d-flex justify-content-center mx-auto">
                    <div>
                        <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                    </div>
                    <div>
                        <input type="text" class="form-control text-center" v-model="captchaSum"  @keypress="isNumber($event)">
                    </div>
                </div>

                <div class="form-group ">
                    <button type="submit" class="btn btn-main mb-3 " id="loginBtn">{{ $t('login.logIn') }}</button>
                </div>

                <div class="form-group ">
                    <button type="button" class="btn" v-on:click="gotoSignup" id="signupBtn">{{ $t('login.signUp') }}</button>
                </div>

                <div class="footer">
                    <span>&copy; HOMS v2.4</span>
                </div>
            </form>

            <form v-on:submit.prevent="onForgotPassword" v-if="state == 'forgot-pass'">
                <div class="alert alert-danger" v-if="errors.login.show">
                    {{ errors.login.msg }}
                </div>
                    
                <div class="btn-group email-or-mobile">
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">{{ $t('login.mobileNumber') }}</button>
                    <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">{{ $t('login.emailAddress') }}</button> 
                </div>

                <div class="form-group" v-if="selected =='email'">
                    <label for="formGroupExampleInput">{{ $t('login.emailAddress') }}:</label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                <div class="form-group" v-if="selected =='accountNo'">
                    <label for="accountNo">{{ $t('login.accountNumber') }}:</label>
                    <input type="text" class="form-control" id="accountNo" v-model="login.loginId" maxlength="13" minlength="13">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                <div class="form-group mobile-container" v-if="selected =='mobile'">
                    <label for="loginId">{{ $t('login.mobileNumber') }}:</label> 
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn text-white" type="button" v-on:click="toggleCountryList">
                                <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                                <span>+{{ mobileCode }}</span>
                            </button>
                        </div>

                        <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
                    </div>

                    <div class="country-list" v-if="showCountry">
                        <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                                <div >
                                <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                                </div>

                                <div class="country-name"> <span> {{ l.country }} </span> </div>  
                            </div>
                        </div>
                    </div>
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                <div class="form-group submit-button text-center pt-3">
                    <button class="btn btn-main" id="forgotBtn">{{ $t('login.submit') }}</button>
                </div>

                <a href="#" v-on:click="toggleLogin('login')" class="forgot">{{ $t('login.backToLogin') }}</a>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: 'mobile',
            country: 'US',
            mobileCode: '1',
            showCountry: false,
            state: 'login',
            login: {
                loginId: '',
            },
            errors: {
                login: {
                    msg: "",
                    show: false,
                    loginId: "",
                    loginPass: ""
                }
            },
            list: {
                mobileCodes: []
            },
            hideOrShow: this.i18n.t('login.showToggle'),
            captchaString: "",
            captchaSum: "",
            captchaImgSrc: "",
            ifResent: false,
            timerInSeconds: 60,
            isDisabled: false,
            captchaTimer: 10,
            chosenLang: localStorage.getItem('lang')
        }
    },

    methods: {
        changeLanguage() {
            localStorage.setItem('lang', this.chosenLang),
            window.location.reload();
        },


        timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                this.isDisabled = true
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.isDisabled = false
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

        captcha() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/login/captcha?fontColor=FFFFFF',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data

                })
                .catch((error) => {
                    // Error code goes here
                    this.errorMsg = error.response.data.msgText
                });
        },

        toggleCountryList: function(){
            console.log('.')
            this.showCountry = !this.showCountry;
        },

        onForgotPassword: function() {
            this.errors.login.show = false;
            let loginId = ""; 

            if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
            } else {
                loginId = this.login.loginId
            }

            const params = {
                siteHost: "wallet.homsworld.org",
                loginId: loginId, 
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/processforgotpin',
            };

            this.axios(options)
                .then(() => { 
                this.errors.login.show = true;
                    this.errors.login.msg = "Temporary PIN has been sent";
                    
                    this.login.loginId = "";
                }).catch((err) => {
                    
                    this.errors.login.show = true;
                        if (err.response.data) { 

                        if(err.response.data.fieldErrors) {

                            err.response.data.fieldErrors.forEach((error) => {

                            switch (error.field) {


                                default:
                                this.errors.login[error.field] = error.defaultMessage;
                                break;
                            }
                            });

                        }  else {

                            this.errors.login.msg = err.response.data.msgText;
                        }
                    }
                })
        },

        resendCode: function() {
            this.errors.login.show = false;
            let loginId = ""; 

            if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
            } else {
                loginId = this.login.loginId
            }
            const params = {
                siteHost: "wallet.homsworld.org",
                loginId: loginId, 
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/processforgotpin',
            };

            this.axios(options)
                .then(() => { 
                    this.errors.login.show = true;
                    this.errors.login.msg = this.i18n.t('login.tempPinSent');
                    this.isResent = true
                    this.timerInSeconds = 60
                    this.timer();
                    this.login.loginId = "";
                }).catch((err) => {
                    
                    this.errors.login.show = true;
                        if (err.response.data) { 

                        if(err.response.data.fieldErrors) {

                            err.response.data.fieldErrors.forEach((error) => {

                            switch (error.field) {


                                default:
                                this.errors.login[error.field] = error.defaultMessage;
                                break;
                            }
                            });

                        }  else {

                            this.errors.login.msg = err.response.data.msgText;
                        }
                    }
                })

            },

        toggleLoginOption(selected) {
            this.selected = selected;
            this.login.loginId = "";

            this.errors.login.loginId = '';
            this.errors.login.loginPass = '';

            if(selected == 'email') {
            this.login.loginId = "";
            }
        },

        toggleLogin: function(state) {
            this.errors.login.show = false;
            this.errors.login.msg = "";
            this.state = state;
        },

        gotoSignup: function(){
            window.location.href = "/signup";
        },

        toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;
        console.log(this.i18n.t)
        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = this.i18n.t('login.hideToggle');
        } else {
          document.getElementById('inputPass').type = 'password';
         this.hideOrShow = this.i18n.t('login.showToggle');
        }
      },

        getMobileCodes: function() {
        let mobilecodes = '/assets/js/mobilecodes.json'

        this.axios.get(mobilecodes)
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
      },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.mobileCode = c.mobileCode; 
        },

        onLogin: function() {
            let hasError = false;

            if(this.selected == 'mobile') {
                if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                    this.errors.login.loginId =  this.i18n.t('login.errors.noMobile');
                    hasError = true;
                } 

                if(this.login.loginId.length < 10) {
                    this.errors.login.loginId = this.i18n.t('login.errors.noMobile');
                    hasError = true;
                } 

                if(this.login.loginId.substring(0,1) === '0') {
                    this.errors.login.loginId = this.i18n.t('login.errors.mobileHasZero');
                    hasError = true;
                }
            } 

            if(this.selected == 'email') {
                if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                    this.errors.login.loginId = this.i18n.t('login.errors.noEmail');
                    hasError = true;
                } 

                if(!this.validateEmail(this.login.loginId)) {
                    this.errors.login.loginId = this.i18n.t('login.errors.noEmail');
                    hasError = true;
                }
            }


            if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
                this.errors.login.loginPass =  this.i18n.t('login.errors.noPin');
                hasError = true;
            } else {
                if(this.login.loginPass.length < 6) {
                    this.errors.login.loginPass =  this.i18n.t('login.errors.pinInvalidDigits');
                    hasError = true;
                }
            }

            if(hasError) {
                return;
            }

            this.errors.login.show = false;

            let loginId = ""; 

            if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
            }
           
            else {
                loginId = this.login.loginId
                  
            }

            const params = {
                loginHost: "wallet.homsworld.org",
                loginId: loginId,
                pin: this.login.loginPass,
                captcha: this.captchaSum
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/login/pin',
            };

            this.axios(options)
                .then((response) => {
                    //console.log(response.data.tokenId)
                    localStorage.setItem("tokenId",response.data.tokenId); 
                    // localStorage.setItem("userName",response.data.userName); 
                    localStorage.setItem("loginIdType",this.selected); 
                    
                    window.location.href = "/";
                    }).catch((err) => {
                        this.errors.login.show = true;
                        if (err.response.data) { 

                        if(err.response.data.fieldErrors) {

                            err.response.data.fieldErrors.forEach((error) => {

                            switch (error.field) {


                                default:
                                this.errors.login[error.field] = error.defaultMessage;
                                break;
                            }
                            });

                        }  else {

                            this.errors.login.msg = err.response.data.msgText;
                        }
                        }
                        setTimeout(()=>{
                            this.errors.login.show = false;
                            this.errors.login.msg = ""
                        },3000);
                        
                })
        },

        validateEmail: function (email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },

    beforeMount() {
        this.captcha();
        this.getMobileCodes();
    }
}
</script>

<style scoped lang="scss">
    #login {

        .wrapper {
            background:linear-gradient(to bottom, #231f20, #070607);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            align-self:center;
            padding: 2em;
            width: 25em;
            margin-top: 1em;
            border-radius:10px;
            form {
                color: #fff;
            }
            .logo {
                display:flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1em;
                img{
                width: 60px;  
                }
        
            }
        }

        .email-or-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;

            .btn {
                color: #FFF;
                border: none;
                outline: none;
                box-shadow: none;
                border-radius: 0;
                &.active {
                color: #c08000;
                border-bottom: 1px solid #c08000;
                }
            }
        }

        .mobile-container {
            position: relative;
            .default-flag {
                width: 33px;
                margin-right:0.25em;
            }

            .country-list {
                position: absolute;
                background: #ccc;
                color: #333;
                top: 70px;
                padding: 0.5em;
                height: 13em;
                overflow: auto;
                    z-index: 3;
                .row {
                    margin: 0 !important;
                    .col-sm-6 {
                        display: flex;
                    padding: 0.5em;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background: darken(#ccc, 10%);
                    }
                    .country-name {
                        margin-left: 0.5em;
                    }

                    span {
                        font-size: 0.9em;
                    }
                    }
                }
            }
        }

        .flag-list-img {
            width: 33px;
        }

        .forgot {
            display: flex;
            justify-content: end;
            color:white;
            margin-top:1em;
            cursor:pointer;
            &:hover {
                text-decoration:underline;
            }
        }
    }
</style>

